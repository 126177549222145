<template>
  <div>
    <h4>Questionnaire Examen clinique</h4>
    
    <data-table
      :data-source="sections"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          data-toggle="modal"
          data-target="#add-questionnaire-section"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
    
    
    
    <modal
      title="Ajout une section"
      id="add-questionnaire-section"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div class="form-group">
          <label>Libelle</label>
          <input
            class="form-control"
            type="text"
            id="libelle"
            name="libelle"
            v-model="libelle"
          >
        </div>
        <div class="form-group text-right">
          <button
            class="btn btn-primary"
            type="submit"
          >
            Ajouter
          </button>
        </div>
      </form>
    </modal>
    <modal
      title="Modification de la section"
      id="update-questionaire-section"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editing"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="update-libelle">Libelle</label>
          <input
            id="update-libelle"
            name="update-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
                  
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import DataTable from '../../../../components/dataTable/local.vue'
import { COMPONENT_TYPE, TEXT_TYPE } from '../../../../components/dataTable/dataType'
import Modal from '../../../../components/modal.vue'
import { ADD_SECTION, UPDATE_SECTION } from '../../../../graphql/sanitaire'
const NbQuestions = () => import('../../../../components/admin/sanitaire/questionaire/sectionNbQuestion.vue')
const Actions = () => import('../../../../components/admin/sanitaire/questionaire/sectionActions.vue')
export default {
    components: {DataTable, Modal},
    data(){
        return {
            libelle: null,
            has_error: false,
            error_msg: null
        }
    },
    watch: {
          selectedType: {
              handler(){
                  if(this.selectedType !== null) {
                      this.libelle = this.selectedType.libelle
                  }
              },
              deep: true
          }
      },
    methods: {
      ...mapMutations({
              done: 'DONE',
              setSelectedObject: 'SET_SELECTED_OBJECT'
          }),
          initForm(){
              this.setSelectedObject(null)
              this.libelle = null
              this.has_error = false
              this.error_msg = null
          },
      adding(){
              let data = {
                  libelle: this.libelle,
              }
              this.$apollo.mutate({
                  mutation: ADD_SECTION,
                  variables: {
                      "section": {
                          ...data
                      }
                  },
                  update: (d) => {
                      console.log(d)
                      console.log(`Section ${this.libelle} add successfully`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          },
          editing(){
              let data = {
                  libelle: this.libelle,
              }
              this.$apollo.mutate({
                  mutation: UPDATE_SECTION,
                  variables: {
                      "section": {
                          ...data
                      },
                      "uid": this.selectedType.uid
                  },
                  update: (d) => {
                      console.log(d)
                      console.log(`Type pronostic ${this.libelle} on ${this.selectedType.uid} updated successfully`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          }
    },
    computed: {
        ...mapGetters({
            sections: 'sanitaire/sections',
            selectedType: 'selectedObject'
        }),
        headers(){
            return [
                {label: 'ID', name: 'uid', type: TEXT_TYPE},
                {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                {label: 'Nombre questionnaires', name: 'nb_question', type: COMPONENT_TYPE, component: NbQuestions},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
                
            ]
        }
    }
}
</script>

<style>

</style>